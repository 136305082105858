<script setup>
import { defineProps, ref, watch } from 'vue'

import { useStatisticStore } from '@/store/statistic'

const props = defineProps(['function', 'title'])

const loading = ref(true)
const from = ref(new Date(new Date().setDate(new Date().getDate() - 30)))
const to = ref(new Date())

const ordersChart = ref({
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ['rgba(100, 181, 246, 0.2)'],
      borderColor: ['#64B5F6'],
      borderWidth: 3,
      fill: true,
      tension: .4
    },
  ],
})
const ordersChartOptions = ref({
  plugins: {
    legend: { display: false, }
  },
  responsive: true,
  hover: { mode: 'index' },
  scale: {
    ticks: { precision: 0 },
    y: { min: 0 }
  }
})

const statisticStore = useStatisticStore()

const search = () => {
  loading.value = true
  statisticStore[props.function](from.value, to.value).then((res) => {
    ordersChart.value.labels = res.data.labels
    ordersChart.value.datasets[0].data = res.data.data

    loading.value = false
  })
}

watch(from, () => {
  search()
})
watch(to, () => {
  search()
})

search()

</script>

<template>
  <div class="card p-fluid">
    <h5>{{ props.title }}</h5>
    <div class="formgrid grid">
      <div class="field col">
        <label for="name2">ตั้งแต่</label>
        <Calendar v-model="from" :showIcon="true" :showButtonBar="true" @change="search()"/>
      </div>
      <div class="field col">
        <label for="email2">จนถึง</label>
        <Calendar v-model="to" :showIcon="true" :showButtonBar="true" />
      </div>
    </div>
  </div>

  <ProgressBar v-if="loading" mode="indeterminate" style="height: 6px" />
  <Chart ref="chart" type="line" :data="ordersChart" :options="ordersChartOptions" />
</template>
