import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useStatisticStore = defineStore('statistic', {
  actions: {
    adminStats: (from, to, userId) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/statistics/admin-stats`, { params: { from, to, user_id: userId } }),
    linkAccountExness: (from, to) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/statistics/link-accounts/exness`, { params: { from, to } }),
    linkAccountXm: (from, to) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/statistics/link-accounts/xm`, { params: { from, to } }),
    newUsers: (from, to) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/statistics/new-users`, { params: { from, to } }),
  }
})
